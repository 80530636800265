import React from "react";

import logo from '../img/logo.svg'

import { useTranslation, Trans } from "react-i18next";

const { hostname } = document.location;

function Footer () {

    const { t } = useTranslation();
    
    
    return(
        <div className="relative">  
            <div className="bg-black">
                <div className="max-w-[1280px] mx-auto pt-[40px]">
                    <img src={logo} alt="" className="xl:w-[133px] xl:h-[70px] w-[84px] h-[49px] xl:hidden block mx-auto"/>
                    <div className="flex gap-[85px] flex-wrap mt-[40px] xl:mt-0 justify-center xl:justify-start">
                        <img src={logo} alt="" className="xl:w-[133px] xl:h-[70px] w-[84px] h-[49px] hidden xl:block"/>
                        <div className="flex">
                            <div className="w-[1px] h-[150px] bg-[#26262F]"></div>
                            <div className="ml-[25px]">
                                <div className="flex gap-[10px]">
                                    <p className="text-[#6E7882] inter text-[14px] mt-[20px]">{t('footer.1')}</p>
                                    <p className="text-[#6E7882] inter text-[14px] mt-[20px]">+441241340109</p>
                                </div>
                                <div className="flex gap-[10px]">
                                    <p className="text-[#6E7882] inter text-[14px] mt-[20px]">{t('footer.2')}</p>
                                    <p className="text-[#6E7882] inter text-[14px] mt-[20px]">Level One, Devonshire House
                                        1 Mayfair Place<br/>
                                        London<br/>
                                        W1J 8AJW 1 J 8 A J<br/>
                                        UNITED KINGDOM</p>
                                </div>
                                <a href="https://register.fca.org.uk/s/firm?id=001b0000040zOBCAA2"><p className="text-[#6E7882] inter text-[14px] mt-[20px]">Regulation</p></a>
                                <div className="flex gap-[10px]">
                                    <p className="text-[#6E7882] inter text-[14px] mt-[20px]">{t('footer.3')}</p>
                                    <p className="text-[#6E7882] inter text-[14px] mt-[20px]">info@{hostname}</p>
                                </div>
                            </div>
                        </div>
                        <div className="ml-[25px] pb-[30px]">
                            <p className="text-[#6E7882] inter text-[14px] mt-[20px]">{t('footer.7')}</p>
                            <p className="text-[#6E7882] inter text-[14px] mt-[20px]"><Trans>{t('footer.8')}</Trans></p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="bg-[#F75524] h-[21px]">
                <div className="max-w-[1280px] mx-auto flex justify-center items-center">
                    <p className="text-black inter text-[14px]">BIM 2002-2024</p>
                </div>
            </div>
        </div>
    );
}

export default Footer;