import React from "react";

import { Link } from "react-router-dom";

import gb from '../../img/Contacts/gb.svg'

import { useTranslation } from "react-i18next";

function Phone () {

    const { t } = useTranslation();

    return(
        <div className="bg-white rounded-t-[40px] xl:rounded-t-[100px] mt-[-100px] relative z-50 overflow-hidden">
            <div className="flex flex-col pt-[50px] xl:pt-[100px] max-w-[1280px] mx-auto">
                <div className="flex justify-between flex-wrap mx-[20px] xl:mx-0">
                    <div className="">
                        <div className="flex items-center">
                            <img src={gb} alt="" className="w-[130px] h-[130px]"/>
                            <div className="ml-[32px]">
                                <p className="text-[16px] xl:text-[22px] inter font-[600]">UNITED KINGDOM</p>
                            </div>
                        </div>
                        <div className="mt-[42px]">
                            <p className="text-[14px] xl:text-[18px] inter leading-[1.2]">{t('contacts.12')}</p>
                            <p className="text-[30px] xl:text-[42px] inter font-[700] leading-[1.2]">+441241340109</p>
                        </div>
                    </div>
                    <div className="xl:flex items-center hidden">
                        <div className="h-[100%] w-[1px] bg-[#6E7882]"></div>
                        <div className="ml-[40px]">
                            <p className="inter text-[18px] leading-[1.6]">{t('contacts.13')}</p>
                            <p className="font-[700] text-[18px] inter leading-[1.6]">+441241340109</p>
                            <p className="text-[#6E7882] text-[14px] inter leading-[1.8]">07:30-16:30 GMT</p>
                            <p className="inter text-[18px] leading-[1.6] mt-[20px]">{t('contacts.13')}</p>
                            <p className="font-[700] text-[18px] inter leading-[1.6]">+441241340109</p>
                        </div>
                    </div>
                    <div className="xl:flex items-center hidden">
                        <div className="h-[100%] w-[1px] bg-[#6E7882]"></div>
                        <div className="ml-[40px]">
                            <p className="font-[700] text-[16px] inter leading-[1.6] max-w-[300px]">1 Mayfair Place, London, W1J 8AJW 1 J 8 A J, UNITED KINGDOM</p>
                            <Link to="https://maps.app.goo.gl/wDr6R9ycu1svS3oc7"><div className="w-[187px] h-[48px] mt-[20px] border border-[#F75524] rounded-[16px] bg-[#FF3E0433] flex items-center justify-center cursor-pointer">
                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                    <path d="M12.0232 8.72948L9.94766 11.7946L6.8784 14.8485C5.73691 13.3978 4.49043 11.9924 3.73047 10.2926L5.38484 7.75827L7.92223 5.3103C7.07418 6.35839 7.1375 7.89987 8.11223 8.8746C9.15504 9.91741 10.8454 9.91741 11.8881 8.8746C11.9349 8.8279 11.98 8.7795 12.0232 8.72948Z" fill="#ECB72B"/>
                                    <path d="M12.0709 8.67204C12.9264 7.62345 12.8657 6.07665 11.8878 5.09931C10.845 4.05649 9.15469 4.05649 8.11191 5.09931C8.06855 5.14273 8.02666 5.18762 7.98633 5.23388L9.73676 2.38829L12.1116 0.325439C13.8853 0.886924 15.3523 2.13712 16.1994 3.76204L14.4669 6.55563L12.0709 8.67204Z" fill="#5085F7"/>
                                    <path d="M7.92164 5.31041L3.72977 10.2928C3.31402 9.36377 3.04375 8.34682 3.01586 7.18619C3.01375 7.1199 3.01269 7.05357 3.0127 6.98725C3.0127 5.27951 3.62539 3.71424 4.6434 2.50049L7.92164 5.31041Z" fill="#DA2F2A"/>
                                    <path d="M7.92191 5.31051L4.64355 2.50039C5.92543 0.97207 7.84902 0 10 0C10.7362 0 11.4457 0.113906 12.112 0.325L7.92191 5.31051Z" fill="#4274EB"/>
                                    <path d="M16.987 6.98688C16.987 5.82364 16.7027 4.72677 16.1998 3.76196L6.87793 14.8489C7.93703 16.1961 8.90477 17.5827 9.30695 19.2807C9.31984 19.3351 9.33371 19.3986 9.34848 19.4701C9.4943 20.1766 10.5059 20.1766 10.6517 19.4701C10.6665 19.3986 10.6804 19.3351 10.6932 19.2807C11.7654 14.7537 16.8576 12.4402 16.984 7.18626C16.9858 7.12001 16.987 7.05356 16.987 6.98688Z" fill="#60A850"/>
                                </svg>
                                <p className="text-[#F75524] inter text-[14px] ml-[5px]">{t('contacts.15')}</p>
                            </div></Link>
                        </div>
                    </div>
                </div>
            </div>
            {/* <div className="pt-[50px] xl:pt-[100px] max-w-[1280px] mx-auto">
                <div className="flex flex-wrap gap-[25px] mx-[20px] xl:mx-0">
                <div className="w-[588px] xl:h-[310px] border border-[#6E7882] rounded-[40px] flex-grow-[1]">
                        <div className="m-[30px]">
                            <div className="flex items-center">
                                <img src={gb} alt="" className="w-[70px] h-[70px]"/>
                                <div className="ml-[19px]">
                                    <p className="text-[16px] xl:text-[22px] inter font-[600]">Великобритания</p>
                                    <p className="text-[14px] inter mt-[5px]">BIM UK Ltd</p>
                                </div>
                            </div>
                            <div className="h-[1px] w-full bg-[#D9D9D9] my-[30px]"></div>
                            <div className="flex justify-between">
                                <div className="flex items-center">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" fill="none">
                                        <path d="M40 20C40 31.0457 31.0457 40 20 40C8.9543 40 0 31.0457 0 20C0 8.9543 8.9543 0 20 0C31.0457 0 40 8.9543 40 20Z" fill="#6E7882"/>
                                        <path d="M22.7964 10H16.1418C14.9674 10.0013 14.0158 10.953 14.0146 12.1274V27.8728C14.0158 29.0472 14.9675 29.9988 16.1418 30H22.7964C23.9708 29.9988 24.9224 29.0471 24.9237 27.8728V12.1274C24.9224 10.953 23.9708 10.0014 22.7964 10ZM19.4692 28.1819C18.967 28.1819 18.56 27.7749 18.56 27.2727C18.56 26.7707 18.967 26.3636 19.4692 26.3636C19.9712 26.3636 20.3782 26.7706 20.3782 27.2727C20.3782 27.7749 19.9713 28.1819 19.4692 28.1819ZM20.8328 12.7273H18.1056C17.8546 12.7273 17.651 12.5237 17.651 12.2727C17.651 12.0216 17.8545 11.8182 18.1056 11.8182H20.8328C21.0838 11.8182 21.2873 12.0216 21.2873 12.2727C21.2873 12.5237 21.0839 12.7273 20.8328 12.7273Z" fill="white"/>
                                    </svg>
                                    <p className="font-[700] inter text-[18px] ml-[10px]">+44 (0) 20 7576 958</p>
                                </div>
                                <div className="flex items-center">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" fill="none">
                                        <path d="M40 20C40 31.0457 31.0457 40 20 40C8.9543 40 0 31.0457 0 20C0 8.9543 8.9543 0 20 0C31.0457 0 40 8.9543 40 20Z" fill="#6E7882"/>
                                        <path d="M16.1543 22.5V29.1667C16.1543 29.3877 16.2353 29.5996 16.3796 29.7559C16.5239 29.9122 16.7195 30 16.9235 30H23.0774C23.2814 30 23.477 29.9122 23.6213 29.7559C23.7656 29.5996 23.8466 29.3877 23.8466 29.1667V22.5H16.1543ZM21.5389 28.3333H18.462C18.258 28.3333 18.0623 28.2455 17.9181 28.0893C17.7738 27.933 17.6928 27.721 17.6928 27.5C17.6928 27.279 17.7738 27.067 17.9181 26.9107C18.0623 26.7545 18.258 26.6667 18.462 26.6667H21.5389C21.7429 26.6667 21.9386 26.7545 22.0828 26.9107C22.2271 27.067 22.3081 27.279 22.3081 27.5C22.3081 27.721 22.2271 27.933 22.0828 28.0893C21.9386 28.2455 21.7429 28.3333 21.5389 28.3333ZM21.5389 25.8333H18.462C18.258 25.8333 18.0623 25.7455 17.9181 25.5893C17.7738 25.433 17.6928 25.221 17.6928 25C17.6928 24.779 17.7738 24.567 17.9181 24.4107C18.0623 24.2545 18.258 24.1667 18.462 24.1667H21.5389C21.7429 24.1667 21.9386 24.2545 22.0828 24.4107C22.2271 24.567 22.3081 24.779 22.3081 25C22.3081 25.221 22.2271 25.433 22.0828 25.5893C21.9386 25.7455 21.7429 25.8333 21.5389 25.8333Z" fill="white"/>
                                        <path d="M27.6923 15H12.3077C12.0045 14.9996 11.7043 15.0639 11.4241 15.1894C11.1439 15.3149 10.8894 15.499 10.675 15.7313C10.4606 15.9635 10.2907 16.2393 10.1748 16.5428C10.059 16.8463 9.99959 17.1716 10 17.5V24.1667C10.0018 24.8291 10.2455 25.4638 10.6779 25.9322C11.1103 26.4007 11.6962 26.6647 12.3077 26.6667H14.6154V21.6667C14.616 21.4459 14.6972 21.2343 14.8414 21.0781C14.9855 20.922 15.1808 20.834 15.3846 20.8333H24.6154C24.8192 20.834 25.0145 20.922 25.1586 21.0781C25.3028 21.2343 25.384 21.4459 25.3846 21.6667V26.6667H27.6923C28.3038 26.6647 28.8897 26.4007 29.3221 25.9322C29.7545 25.4638 29.9982 24.8291 30 24.1667V17.5C30.0004 17.1716 29.941 16.8463 29.8252 16.5428C29.7093 16.2393 29.5394 15.9635 29.325 15.7313C29.1106 15.499 28.8561 15.3149 28.5759 15.1894C28.2957 15.0639 27.9955 14.9996 27.6923 15ZM26.1538 19.1667C26.0525 19.1679 25.952 19.1472 25.8581 19.1057C25.7643 19.0643 25.679 19.003 25.6074 18.9253C25.5357 18.8477 25.4791 18.7553 25.4409 18.6537C25.4026 18.552 25.3835 18.4431 25.3846 18.3333C25.3846 18.1685 25.4297 18.0074 25.5143 17.8704C25.5988 17.7333 25.7189 17.6265 25.8595 17.5634C26 17.5004 26.1547 17.4839 26.3039 17.516C26.4531 17.5482 26.5902 17.6275 26.6978 17.7441C26.8054 17.8606 26.8786 18.0091 26.9083 18.1708C26.938 18.3324 26.9227 18.5 26.8645 18.6522C26.8063 18.8045 26.7077 18.9347 26.5812 19.0262C26.4547 19.1178 26.306 19.1667 26.1538 19.1667ZM25.3846 10.8333V14.1667H14.6154V10.8333C14.616 10.6125 14.6972 10.4009 14.8414 10.2448C14.9855 10.0887 15.1808 10.0007 15.3846 10H24.6154C24.8192 10.0007 25.0145 10.0887 25.1586 10.2448C25.3028 10.4009 25.384 10.6125 25.3846 10.8333Z" fill="white"/>
                                    </svg>
                                    <p className="font-[700] inter text-[18px] ml-[10px]">+44 (0) 20 7576 958</p>
                                </div>
                            </div>
                            <div className="h-[1px] w-full bg-[#D9D9D9] my-[30px]"></div>
                            <div className="flex items-center">
                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                    <path d="M12.0232 8.72948L9.94766 11.7946L6.8784 14.8485C5.73691 13.3978 4.49043 11.9924 3.73047 10.2926L5.38484 7.75827L7.92223 5.3103C7.07418 6.35839 7.1375 7.89987 8.11223 8.8746C9.15504 9.91741 10.8454 9.91741 11.8881 8.8746C11.9349 8.8279 11.98 8.7795 12.0232 8.72948Z" fill="#ECB72B"/>
                                    <path d="M12.0709 8.67204C12.9264 7.62345 12.8657 6.07665 11.8878 5.09931C10.845 4.05649 9.15469 4.05649 8.11191 5.09931C8.06855 5.14273 8.02666 5.18762 7.98633 5.23388L9.73676 2.38829L12.1116 0.325439C13.8853 0.886924 15.3523 2.13712 16.1994 3.76204L14.4669 6.55563L12.0709 8.67204Z" fill="#5085F7"/>
                                    <path d="M7.92164 5.31041L3.72977 10.2928C3.31402 9.36377 3.04375 8.34682 3.01586 7.18619C3.01375 7.1199 3.01269 7.05357 3.0127 6.98725C3.0127 5.27951 3.62539 3.71424 4.6434 2.50049L7.92164 5.31041Z" fill="#DA2F2A"/>
                                    <path d="M7.92191 5.31051L4.64355 2.50039C5.92543 0.97207 7.84902 0 10 0C10.7362 0 11.4457 0.113906 12.112 0.325L7.92191 5.31051Z" fill="#4274EB"/>
                                    <path d="M16.987 6.98688C16.987 5.82364 16.7027 4.72677 16.1998 3.76196L6.87793 14.8489C7.93703 16.1961 8.90477 17.5827 9.30695 19.2807C9.31984 19.3351 9.33371 19.3986 9.34848 19.4701C9.4943 20.1766 10.5059 20.1766 10.6517 19.4701C10.6665 19.3986 10.6804 19.3351 10.6932 19.2807C11.7654 14.7537 16.8576 12.4402 16.984 7.18626C16.9858 7.12001 16.987 7.05356 16.987 6.98688Z" fill="#60A850"/>
                                </svg>
                                <p className="inter text-[18px] ml-[11px]">12-16 Basinghall str.,Лондон, EC2V4BQ, Великобритания</p>
                            </div>
                        </div>
                    </div>
                    <div className="w-[588px] xl:h-[310px] border border-[#6E7882] rounded-[40px] flex-grow-[1]">
                        <div className="m-[30px]">
                            <div className="flex items-center">
                                <img src={gb} alt="" className="w-[70px] h-[70px]"/>
                                <div className="ml-[19px]">
                                    <p className="text-[16px] xl:text-[22px] inter font-[600]">Великобритания</p>
                                    <p className="text-[14px] inter mt-[5px]">BIM UK Ltd</p>
                                </div>
                            </div>
                            <div className="h-[1px] w-full bg-[#D9D9D9] my-[30px]"></div>
                            <div className="flex justify-between">
                                <div className="flex items-center">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" fill="none">
                                        <path d="M40 20C40 31.0457 31.0457 40 20 40C8.9543 40 0 31.0457 0 20C0 8.9543 8.9543 0 20 0C31.0457 0 40 8.9543 40 20Z" fill="#6E7882"/>
                                        <path d="M22.7964 10H16.1418C14.9674 10.0013 14.0158 10.953 14.0146 12.1274V27.8728C14.0158 29.0472 14.9675 29.9988 16.1418 30H22.7964C23.9708 29.9988 24.9224 29.0471 24.9237 27.8728V12.1274C24.9224 10.953 23.9708 10.0014 22.7964 10ZM19.4692 28.1819C18.967 28.1819 18.56 27.7749 18.56 27.2727C18.56 26.7707 18.967 26.3636 19.4692 26.3636C19.9712 26.3636 20.3782 26.7706 20.3782 27.2727C20.3782 27.7749 19.9713 28.1819 19.4692 28.1819ZM20.8328 12.7273H18.1056C17.8546 12.7273 17.651 12.5237 17.651 12.2727C17.651 12.0216 17.8545 11.8182 18.1056 11.8182H20.8328C21.0838 11.8182 21.2873 12.0216 21.2873 12.2727C21.2873 12.5237 21.0839 12.7273 20.8328 12.7273Z" fill="white"/>
                                    </svg>
                                    <p className="font-[700] inter text-[18px] ml-[10px]">+44 (0) 20 7576 958</p>
                                </div>
                                <div className="flex items-center">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" fill="none">
                                        <path d="M40 20C40 31.0457 31.0457 40 20 40C8.9543 40 0 31.0457 0 20C0 8.9543 8.9543 0 20 0C31.0457 0 40 8.9543 40 20Z" fill="#6E7882"/>
                                        <path d="M16.1543 22.5V29.1667C16.1543 29.3877 16.2353 29.5996 16.3796 29.7559C16.5239 29.9122 16.7195 30 16.9235 30H23.0774C23.2814 30 23.477 29.9122 23.6213 29.7559C23.7656 29.5996 23.8466 29.3877 23.8466 29.1667V22.5H16.1543ZM21.5389 28.3333H18.462C18.258 28.3333 18.0623 28.2455 17.9181 28.0893C17.7738 27.933 17.6928 27.721 17.6928 27.5C17.6928 27.279 17.7738 27.067 17.9181 26.9107C18.0623 26.7545 18.258 26.6667 18.462 26.6667H21.5389C21.7429 26.6667 21.9386 26.7545 22.0828 26.9107C22.2271 27.067 22.3081 27.279 22.3081 27.5C22.3081 27.721 22.2271 27.933 22.0828 28.0893C21.9386 28.2455 21.7429 28.3333 21.5389 28.3333ZM21.5389 25.8333H18.462C18.258 25.8333 18.0623 25.7455 17.9181 25.5893C17.7738 25.433 17.6928 25.221 17.6928 25C17.6928 24.779 17.7738 24.567 17.9181 24.4107C18.0623 24.2545 18.258 24.1667 18.462 24.1667H21.5389C21.7429 24.1667 21.9386 24.2545 22.0828 24.4107C22.2271 24.567 22.3081 24.779 22.3081 25C22.3081 25.221 22.2271 25.433 22.0828 25.5893C21.9386 25.7455 21.7429 25.8333 21.5389 25.8333Z" fill="white"/>
                                        <path d="M27.6923 15H12.3077C12.0045 14.9996 11.7043 15.0639 11.4241 15.1894C11.1439 15.3149 10.8894 15.499 10.675 15.7313C10.4606 15.9635 10.2907 16.2393 10.1748 16.5428C10.059 16.8463 9.99959 17.1716 10 17.5V24.1667C10.0018 24.8291 10.2455 25.4638 10.6779 25.9322C11.1103 26.4007 11.6962 26.6647 12.3077 26.6667H14.6154V21.6667C14.616 21.4459 14.6972 21.2343 14.8414 21.0781C14.9855 20.922 15.1808 20.834 15.3846 20.8333H24.6154C24.8192 20.834 25.0145 20.922 25.1586 21.0781C25.3028 21.2343 25.384 21.4459 25.3846 21.6667V26.6667H27.6923C28.3038 26.6647 28.8897 26.4007 29.3221 25.9322C29.7545 25.4638 29.9982 24.8291 30 24.1667V17.5C30.0004 17.1716 29.941 16.8463 29.8252 16.5428C29.7093 16.2393 29.5394 15.9635 29.325 15.7313C29.1106 15.499 28.8561 15.3149 28.5759 15.1894C28.2957 15.0639 27.9955 14.9996 27.6923 15ZM26.1538 19.1667C26.0525 19.1679 25.952 19.1472 25.8581 19.1057C25.7643 19.0643 25.679 19.003 25.6074 18.9253C25.5357 18.8477 25.4791 18.7553 25.4409 18.6537C25.4026 18.552 25.3835 18.4431 25.3846 18.3333C25.3846 18.1685 25.4297 18.0074 25.5143 17.8704C25.5988 17.7333 25.7189 17.6265 25.8595 17.5634C26 17.5004 26.1547 17.4839 26.3039 17.516C26.4531 17.5482 26.5902 17.6275 26.6978 17.7441C26.8054 17.8606 26.8786 18.0091 26.9083 18.1708C26.938 18.3324 26.9227 18.5 26.8645 18.6522C26.8063 18.8045 26.7077 18.9347 26.5812 19.0262C26.4547 19.1178 26.306 19.1667 26.1538 19.1667ZM25.3846 10.8333V14.1667H14.6154V10.8333C14.616 10.6125 14.6972 10.4009 14.8414 10.2448C14.9855 10.0887 15.1808 10.0007 15.3846 10H24.6154C24.8192 10.0007 25.0145 10.0887 25.1586 10.2448C25.3028 10.4009 25.384 10.6125 25.3846 10.8333Z" fill="white"/>
                                    </svg>
                                    <p className="font-[700] inter text-[18px] ml-[10px]">+44 (0) 20 7576 958</p>
                                </div>
                            </div>
                            <div className="h-[1px] w-full bg-[#D9D9D9] my-[30px]"></div>
                            <div className="flex items-center">
                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                    <path d="M12.0232 8.72948L9.94766 11.7946L6.8784 14.8485C5.73691 13.3978 4.49043 11.9924 3.73047 10.2926L5.38484 7.75827L7.92223 5.3103C7.07418 6.35839 7.1375 7.89987 8.11223 8.8746C9.15504 9.91741 10.8454 9.91741 11.8881 8.8746C11.9349 8.8279 11.98 8.7795 12.0232 8.72948Z" fill="#ECB72B"/>
                                    <path d="M12.0709 8.67204C12.9264 7.62345 12.8657 6.07665 11.8878 5.09931C10.845 4.05649 9.15469 4.05649 8.11191 5.09931C8.06855 5.14273 8.02666 5.18762 7.98633 5.23388L9.73676 2.38829L12.1116 0.325439C13.8853 0.886924 15.3523 2.13712 16.1994 3.76204L14.4669 6.55563L12.0709 8.67204Z" fill="#5085F7"/>
                                    <path d="M7.92164 5.31041L3.72977 10.2928C3.31402 9.36377 3.04375 8.34682 3.01586 7.18619C3.01375 7.1199 3.01269 7.05357 3.0127 6.98725C3.0127 5.27951 3.62539 3.71424 4.6434 2.50049L7.92164 5.31041Z" fill="#DA2F2A"/>
                                    <path d="M7.92191 5.31051L4.64355 2.50039C5.92543 0.97207 7.84902 0 10 0C10.7362 0 11.4457 0.113906 12.112 0.325L7.92191 5.31051Z" fill="#4274EB"/>
                                    <path d="M16.987 6.98688C16.987 5.82364 16.7027 4.72677 16.1998 3.76196L6.87793 14.8489C7.93703 16.1961 8.90477 17.5827 9.30695 19.2807C9.31984 19.3351 9.33371 19.3986 9.34848 19.4701C9.4943 20.1766 10.5059 20.1766 10.6517 19.4701C10.6665 19.3986 10.6804 19.3351 10.6932 19.2807C11.7654 14.7537 16.8576 12.4402 16.984 7.18626C16.9858 7.12001 16.987 7.05356 16.987 6.98688Z" fill="#60A850"/>
                                </svg>
                                <p className="inter text-[18px] ml-[11px]">12-16 Basinghall str.,Лондон, EC2V4BQ, Великобритания</p>
                            </div>
                        </div>
                    </div>
                    <div className="w-[588px] xl:h-[310px] border border-[#6E7882] rounded-[40px] flex-grow-[1]">
                        <div className="m-[30px]">
                            <div className="flex items-center">
                                <img src={gb} alt="" className="w-[70px] h-[70px]"/>
                                <div className="ml-[19px]">
                                    <p className="text-[16px] xl:text-[22px] inter font-[600]">Великобритания</p>
                                    <p className="text-[14px] inter mt-[5px]">BIM UK Ltd</p>
                                </div>
                            </div>
                            <div className="h-[1px] w-full bg-[#D9D9D9] my-[30px]"></div>
                            <div className="flex justify-between">
                                <div className="flex items-center">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" fill="none">
                                        <path d="M40 20C40 31.0457 31.0457 40 20 40C8.9543 40 0 31.0457 0 20C0 8.9543 8.9543 0 20 0C31.0457 0 40 8.9543 40 20Z" fill="#6E7882"/>
                                        <path d="M22.7964 10H16.1418C14.9674 10.0013 14.0158 10.953 14.0146 12.1274V27.8728C14.0158 29.0472 14.9675 29.9988 16.1418 30H22.7964C23.9708 29.9988 24.9224 29.0471 24.9237 27.8728V12.1274C24.9224 10.953 23.9708 10.0014 22.7964 10ZM19.4692 28.1819C18.967 28.1819 18.56 27.7749 18.56 27.2727C18.56 26.7707 18.967 26.3636 19.4692 26.3636C19.9712 26.3636 20.3782 26.7706 20.3782 27.2727C20.3782 27.7749 19.9713 28.1819 19.4692 28.1819ZM20.8328 12.7273H18.1056C17.8546 12.7273 17.651 12.5237 17.651 12.2727C17.651 12.0216 17.8545 11.8182 18.1056 11.8182H20.8328C21.0838 11.8182 21.2873 12.0216 21.2873 12.2727C21.2873 12.5237 21.0839 12.7273 20.8328 12.7273Z" fill="white"/>
                                    </svg>
                                    <p className="font-[700] inter text-[18px] ml-[10px]">+44 (0) 20 7576 958</p>
                                </div>
                                <div className="flex items-center">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" fill="none">
                                        <path d="M40 20C40 31.0457 31.0457 40 20 40C8.9543 40 0 31.0457 0 20C0 8.9543 8.9543 0 20 0C31.0457 0 40 8.9543 40 20Z" fill="#6E7882"/>
                                        <path d="M16.1543 22.5V29.1667C16.1543 29.3877 16.2353 29.5996 16.3796 29.7559C16.5239 29.9122 16.7195 30 16.9235 30H23.0774C23.2814 30 23.477 29.9122 23.6213 29.7559C23.7656 29.5996 23.8466 29.3877 23.8466 29.1667V22.5H16.1543ZM21.5389 28.3333H18.462C18.258 28.3333 18.0623 28.2455 17.9181 28.0893C17.7738 27.933 17.6928 27.721 17.6928 27.5C17.6928 27.279 17.7738 27.067 17.9181 26.9107C18.0623 26.7545 18.258 26.6667 18.462 26.6667H21.5389C21.7429 26.6667 21.9386 26.7545 22.0828 26.9107C22.2271 27.067 22.3081 27.279 22.3081 27.5C22.3081 27.721 22.2271 27.933 22.0828 28.0893C21.9386 28.2455 21.7429 28.3333 21.5389 28.3333ZM21.5389 25.8333H18.462C18.258 25.8333 18.0623 25.7455 17.9181 25.5893C17.7738 25.433 17.6928 25.221 17.6928 25C17.6928 24.779 17.7738 24.567 17.9181 24.4107C18.0623 24.2545 18.258 24.1667 18.462 24.1667H21.5389C21.7429 24.1667 21.9386 24.2545 22.0828 24.4107C22.2271 24.567 22.3081 24.779 22.3081 25C22.3081 25.221 22.2271 25.433 22.0828 25.5893C21.9386 25.7455 21.7429 25.8333 21.5389 25.8333Z" fill="white"/>
                                        <path d="M27.6923 15H12.3077C12.0045 14.9996 11.7043 15.0639 11.4241 15.1894C11.1439 15.3149 10.8894 15.499 10.675 15.7313C10.4606 15.9635 10.2907 16.2393 10.1748 16.5428C10.059 16.8463 9.99959 17.1716 10 17.5V24.1667C10.0018 24.8291 10.2455 25.4638 10.6779 25.9322C11.1103 26.4007 11.6962 26.6647 12.3077 26.6667H14.6154V21.6667C14.616 21.4459 14.6972 21.2343 14.8414 21.0781C14.9855 20.922 15.1808 20.834 15.3846 20.8333H24.6154C24.8192 20.834 25.0145 20.922 25.1586 21.0781C25.3028 21.2343 25.384 21.4459 25.3846 21.6667V26.6667H27.6923C28.3038 26.6647 28.8897 26.4007 29.3221 25.9322C29.7545 25.4638 29.9982 24.8291 30 24.1667V17.5C30.0004 17.1716 29.941 16.8463 29.8252 16.5428C29.7093 16.2393 29.5394 15.9635 29.325 15.7313C29.1106 15.499 28.8561 15.3149 28.5759 15.1894C28.2957 15.0639 27.9955 14.9996 27.6923 15ZM26.1538 19.1667C26.0525 19.1679 25.952 19.1472 25.8581 19.1057C25.7643 19.0643 25.679 19.003 25.6074 18.9253C25.5357 18.8477 25.4791 18.7553 25.4409 18.6537C25.4026 18.552 25.3835 18.4431 25.3846 18.3333C25.3846 18.1685 25.4297 18.0074 25.5143 17.8704C25.5988 17.7333 25.7189 17.6265 25.8595 17.5634C26 17.5004 26.1547 17.4839 26.3039 17.516C26.4531 17.5482 26.5902 17.6275 26.6978 17.7441C26.8054 17.8606 26.8786 18.0091 26.9083 18.1708C26.938 18.3324 26.9227 18.5 26.8645 18.6522C26.8063 18.8045 26.7077 18.9347 26.5812 19.0262C26.4547 19.1178 26.306 19.1667 26.1538 19.1667ZM25.3846 10.8333V14.1667H14.6154V10.8333C14.616 10.6125 14.6972 10.4009 14.8414 10.2448C14.9855 10.0887 15.1808 10.0007 15.3846 10H24.6154C24.8192 10.0007 25.0145 10.0887 25.1586 10.2448C25.3028 10.4009 25.384 10.6125 25.3846 10.8333Z" fill="white"/>
                                    </svg>
                                    <p className="font-[700] inter text-[18px] ml-[10px]">+44 (0) 20 7576 958</p>
                                </div>
                            </div>
                            <div className="h-[1px] w-full bg-[#D9D9D9] my-[30px]"></div>
                            <div className="flex items-center">
                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                    <path d="M12.0232 8.72948L9.94766 11.7946L6.8784 14.8485C5.73691 13.3978 4.49043 11.9924 3.73047 10.2926L5.38484 7.75827L7.92223 5.3103C7.07418 6.35839 7.1375 7.89987 8.11223 8.8746C9.15504 9.91741 10.8454 9.91741 11.8881 8.8746C11.9349 8.8279 11.98 8.7795 12.0232 8.72948Z" fill="#ECB72B"/>
                                    <path d="M12.0709 8.67204C12.9264 7.62345 12.8657 6.07665 11.8878 5.09931C10.845 4.05649 9.15469 4.05649 8.11191 5.09931C8.06855 5.14273 8.02666 5.18762 7.98633 5.23388L9.73676 2.38829L12.1116 0.325439C13.8853 0.886924 15.3523 2.13712 16.1994 3.76204L14.4669 6.55563L12.0709 8.67204Z" fill="#5085F7"/>
                                    <path d="M7.92164 5.31041L3.72977 10.2928C3.31402 9.36377 3.04375 8.34682 3.01586 7.18619C3.01375 7.1199 3.01269 7.05357 3.0127 6.98725C3.0127 5.27951 3.62539 3.71424 4.6434 2.50049L7.92164 5.31041Z" fill="#DA2F2A"/>
                                    <path d="M7.92191 5.31051L4.64355 2.50039C5.92543 0.97207 7.84902 0 10 0C10.7362 0 11.4457 0.113906 12.112 0.325L7.92191 5.31051Z" fill="#4274EB"/>
                                    <path d="M16.987 6.98688C16.987 5.82364 16.7027 4.72677 16.1998 3.76196L6.87793 14.8489C7.93703 16.1961 8.90477 17.5827 9.30695 19.2807C9.31984 19.3351 9.33371 19.3986 9.34848 19.4701C9.4943 20.1766 10.5059 20.1766 10.6517 19.4701C10.6665 19.3986 10.6804 19.3351 10.6932 19.2807C11.7654 14.7537 16.8576 12.4402 16.984 7.18626C16.9858 7.12001 16.987 7.05356 16.987 6.98688Z" fill="#60A850"/>
                                </svg>
                                <p className="inter text-[18px] ml-[11px]">12-16 Basinghall str.,Лондон, EC2V4BQ, Великобритания</p>
                            </div>
                        </div>
                    </div>
                    <div className="w-[588px] xl:h-[310px] border border-[#6E7882] rounded-[40px] flex-grow-[1]">
                        <div className="m-[30px]">
                            <div className="flex items-center">
                                <img src={gb} alt="" className="w-[70px] h-[70px]"/>
                                <div className="ml-[19px]">
                                    <p className="text-[16px] xl:text-[22px] inter font-[600]">Великобритания</p>
                                    <p className="text-[14px] inter mt-[5px]">BIM UK Ltd</p>
                                </div>
                            </div>
                            <div className="h-[1px] w-full bg-[#D9D9D9] my-[30px]"></div>
                            <div className="flex justify-between">
                                <div className="flex items-center">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" fill="none">
                                        <path d="M40 20C40 31.0457 31.0457 40 20 40C8.9543 40 0 31.0457 0 20C0 8.9543 8.9543 0 20 0C31.0457 0 40 8.9543 40 20Z" fill="#6E7882"/>
                                        <path d="M22.7964 10H16.1418C14.9674 10.0013 14.0158 10.953 14.0146 12.1274V27.8728C14.0158 29.0472 14.9675 29.9988 16.1418 30H22.7964C23.9708 29.9988 24.9224 29.0471 24.9237 27.8728V12.1274C24.9224 10.953 23.9708 10.0014 22.7964 10ZM19.4692 28.1819C18.967 28.1819 18.56 27.7749 18.56 27.2727C18.56 26.7707 18.967 26.3636 19.4692 26.3636C19.9712 26.3636 20.3782 26.7706 20.3782 27.2727C20.3782 27.7749 19.9713 28.1819 19.4692 28.1819ZM20.8328 12.7273H18.1056C17.8546 12.7273 17.651 12.5237 17.651 12.2727C17.651 12.0216 17.8545 11.8182 18.1056 11.8182H20.8328C21.0838 11.8182 21.2873 12.0216 21.2873 12.2727C21.2873 12.5237 21.0839 12.7273 20.8328 12.7273Z" fill="white"/>
                                    </svg>
                                    <p className="font-[700] inter text-[18px] ml-[10px]">+44 (0) 20 7576 958</p>
                                </div>
                                <div className="flex items-center">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" fill="none">
                                        <path d="M40 20C40 31.0457 31.0457 40 20 40C8.9543 40 0 31.0457 0 20C0 8.9543 8.9543 0 20 0C31.0457 0 40 8.9543 40 20Z" fill="#6E7882"/>
                                        <path d="M16.1543 22.5V29.1667C16.1543 29.3877 16.2353 29.5996 16.3796 29.7559C16.5239 29.9122 16.7195 30 16.9235 30H23.0774C23.2814 30 23.477 29.9122 23.6213 29.7559C23.7656 29.5996 23.8466 29.3877 23.8466 29.1667V22.5H16.1543ZM21.5389 28.3333H18.462C18.258 28.3333 18.0623 28.2455 17.9181 28.0893C17.7738 27.933 17.6928 27.721 17.6928 27.5C17.6928 27.279 17.7738 27.067 17.9181 26.9107C18.0623 26.7545 18.258 26.6667 18.462 26.6667H21.5389C21.7429 26.6667 21.9386 26.7545 22.0828 26.9107C22.2271 27.067 22.3081 27.279 22.3081 27.5C22.3081 27.721 22.2271 27.933 22.0828 28.0893C21.9386 28.2455 21.7429 28.3333 21.5389 28.3333ZM21.5389 25.8333H18.462C18.258 25.8333 18.0623 25.7455 17.9181 25.5893C17.7738 25.433 17.6928 25.221 17.6928 25C17.6928 24.779 17.7738 24.567 17.9181 24.4107C18.0623 24.2545 18.258 24.1667 18.462 24.1667H21.5389C21.7429 24.1667 21.9386 24.2545 22.0828 24.4107C22.2271 24.567 22.3081 24.779 22.3081 25C22.3081 25.221 22.2271 25.433 22.0828 25.5893C21.9386 25.7455 21.7429 25.8333 21.5389 25.8333Z" fill="white"/>
                                        <path d="M27.6923 15H12.3077C12.0045 14.9996 11.7043 15.0639 11.4241 15.1894C11.1439 15.3149 10.8894 15.499 10.675 15.7313C10.4606 15.9635 10.2907 16.2393 10.1748 16.5428C10.059 16.8463 9.99959 17.1716 10 17.5V24.1667C10.0018 24.8291 10.2455 25.4638 10.6779 25.9322C11.1103 26.4007 11.6962 26.6647 12.3077 26.6667H14.6154V21.6667C14.616 21.4459 14.6972 21.2343 14.8414 21.0781C14.9855 20.922 15.1808 20.834 15.3846 20.8333H24.6154C24.8192 20.834 25.0145 20.922 25.1586 21.0781C25.3028 21.2343 25.384 21.4459 25.3846 21.6667V26.6667H27.6923C28.3038 26.6647 28.8897 26.4007 29.3221 25.9322C29.7545 25.4638 29.9982 24.8291 30 24.1667V17.5C30.0004 17.1716 29.941 16.8463 29.8252 16.5428C29.7093 16.2393 29.5394 15.9635 29.325 15.7313C29.1106 15.499 28.8561 15.3149 28.5759 15.1894C28.2957 15.0639 27.9955 14.9996 27.6923 15ZM26.1538 19.1667C26.0525 19.1679 25.952 19.1472 25.8581 19.1057C25.7643 19.0643 25.679 19.003 25.6074 18.9253C25.5357 18.8477 25.4791 18.7553 25.4409 18.6537C25.4026 18.552 25.3835 18.4431 25.3846 18.3333C25.3846 18.1685 25.4297 18.0074 25.5143 17.8704C25.5988 17.7333 25.7189 17.6265 25.8595 17.5634C26 17.5004 26.1547 17.4839 26.3039 17.516C26.4531 17.5482 26.5902 17.6275 26.6978 17.7441C26.8054 17.8606 26.8786 18.0091 26.9083 18.1708C26.938 18.3324 26.9227 18.5 26.8645 18.6522C26.8063 18.8045 26.7077 18.9347 26.5812 19.0262C26.4547 19.1178 26.306 19.1667 26.1538 19.1667ZM25.3846 10.8333V14.1667H14.6154V10.8333C14.616 10.6125 14.6972 10.4009 14.8414 10.2448C14.9855 10.0887 15.1808 10.0007 15.3846 10H24.6154C24.8192 10.0007 25.0145 10.0887 25.1586 10.2448C25.3028 10.4009 25.384 10.6125 25.3846 10.8333Z" fill="white"/>
                                    </svg>
                                    <p className="font-[700] inter text-[18px] ml-[10px]">+44 (0) 20 7576 958</p>
                                </div>
                            </div>
                            <div className="h-[1px] w-full bg-[#D9D9D9] my-[30px]"></div>
                            <div className="flex items-center">
                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                    <path d="M12.0232 8.72948L9.94766 11.7946L6.8784 14.8485C5.73691 13.3978 4.49043 11.9924 3.73047 10.2926L5.38484 7.75827L7.92223 5.3103C7.07418 6.35839 7.1375 7.89987 8.11223 8.8746C9.15504 9.91741 10.8454 9.91741 11.8881 8.8746C11.9349 8.8279 11.98 8.7795 12.0232 8.72948Z" fill="#ECB72B"/>
                                    <path d="M12.0709 8.67204C12.9264 7.62345 12.8657 6.07665 11.8878 5.09931C10.845 4.05649 9.15469 4.05649 8.11191 5.09931C8.06855 5.14273 8.02666 5.18762 7.98633 5.23388L9.73676 2.38829L12.1116 0.325439C13.8853 0.886924 15.3523 2.13712 16.1994 3.76204L14.4669 6.55563L12.0709 8.67204Z" fill="#5085F7"/>
                                    <path d="M7.92164 5.31041L3.72977 10.2928C3.31402 9.36377 3.04375 8.34682 3.01586 7.18619C3.01375 7.1199 3.01269 7.05357 3.0127 6.98725C3.0127 5.27951 3.62539 3.71424 4.6434 2.50049L7.92164 5.31041Z" fill="#DA2F2A"/>
                                    <path d="M7.92191 5.31051L4.64355 2.50039C5.92543 0.97207 7.84902 0 10 0C10.7362 0 11.4457 0.113906 12.112 0.325L7.92191 5.31051Z" fill="#4274EB"/>
                                    <path d="M16.987 6.98688C16.987 5.82364 16.7027 4.72677 16.1998 3.76196L6.87793 14.8489C7.93703 16.1961 8.90477 17.5827 9.30695 19.2807C9.31984 19.3351 9.33371 19.3986 9.34848 19.4701C9.4943 20.1766 10.5059 20.1766 10.6517 19.4701C10.6665 19.3986 10.6804 19.3351 10.6932 19.2807C11.7654 14.7537 16.8576 12.4402 16.984 7.18626C16.9858 7.12001 16.987 7.05356 16.987 6.98688Z" fill="#60A850"/>
                                </svg>
                                <p className="inter text-[18px] ml-[11px]">12-16 Basinghall str.,Лондон, EC2V4BQ, Великобритания</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}
        </div>
    );
}

export default Phone;